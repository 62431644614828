import React from "react"
import { Section, Container } from "../components/utils"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Hero from "../components/hero"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import styled from "styled-components"

const AboutPage = ({
  data: {
    page: {
      title,
      intro,
      heroImage,
      backgroundContent,
      backgroundImage,
      coreValues,
    },
  },
}) => (
  <Layout>
    <SEO title="About Us" />
    <Hero image={heroImage.fluid}>
      <h1>{title}</h1>
      <div dangerouslySetInnerHTML={{ __html: intro }} />
    </Hero>
    <Section padding="small">
      <BackgroundSection>
        <Img className="bgImage" fluid={backgroundImage.fluid} />
        <div
          className="bgContent center-ruled"
          dangerouslySetInnerHTML={{ __html: backgroundContent }}
        />
      </BackgroundSection>
    </Section>
    <Section padding="medium">
      <Container className="text-center">
        <Values>
          {coreValues.map(block => {
            if (block.model.apiKey === "intro") {
              return (
                <div
                  className="valuesIntro center-ruled"
                  dangerouslySetInnerHTML={{ __html: block.text }}
                />
              )
            } else {
              return <h3 className="value">{block.value}</h3>
            }
          })}
        </Values>
      </Container>
    </Section>
  </Layout>
)

export default AboutPage

const BackgroundSection = styled(Container)`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  .bgContent {
    grid-column: 1/-1;
    grid-row: 2;
    z-index: 1;
    padding: 3rem;
    background: white;
    text-align: center;
    box-shadow: 0px 4px 10px #5c5c5c6e;
    margin-top: -50px;
  }
  .bgImage {
    grid-column: 2/-2;
    grid-row: 1;
  }
  @media (min-width: 768px) {
    .bgImage {
      grid-column: 1/6;
      grid-row: 1;
    }
    .bgContent {
      margin-top: 0;
      margin: 5rem 0;
      grid-column: 4/-1;
      grid-row: 1;
      z-index: 1;
      padding: 3rem;
      background: white;
      margin: 5rem 0;
      text-align: center;
      box-shadow: 0px 4px 10px #5c5c5c6e;
    }
  }
`
const Values = styled.div`
  display: grid;
  @media (min-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
    .value {
      :not(:last-of-type) {
        border-bottom: 0 !important;
        border-right: 1px solid ${props => props.theme.accent};
      }
    }
  }
  .valuesIntro {
    grid-column: 1/-1;
  }
  .value {
    margin: 0;
    padding: 1rem;
    font-size: 1.8rem;
    font-weight: 400;
    :not(:last-of-type) {
      border-bottom: 1px solid ${props => props.theme.accent};
    }
  }
`

export const query = graphql`
  {
    page: datoCmsAboutPage {
      title
      intro
      heroImage {
        fluid {
          ...GatsbyDatoCmsFluid
        }
      }
      backgroundContent
      backgroundImage {
        fluid {
          ...GatsbyDatoCmsFluid
        }
      }
      coreValues {
        ... on DatoCmsIntro {
          model {
            apiKey
          }
          text
        }
        ... on DatoCmsValue {
          model {
            apiKey
          }
          value
        }
      }
    }
  }
`
